import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

export const firestore = firebase.firestore();
export const auth = firebase.auth();
auth.languageCode = 'sv_SV';
export const storage = firebase.storage();
export const TaskState = firebase.storage.TaskState;
export const TaskEvent = firebase.storage.TaskEvent;
export const FieldValue = firebase.firestore.FieldValue;

// Use emulators
if (process.env.REACT_APP_USE_EMULATORS === 'true' || process.env.TEST_WORKER_INDEX) {
  firestore.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);

  // @ts-ignore
  window.login = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };
}
