import constate from 'constate';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';
import { User } from '../types/FirestoreTypings';

function useFirebaseState() {
  const [user, loading] = useAuthState(auth);
  const [profile, profileLoading] = useDocumentData<User>(user ? firestore.collection('users').doc(user.uid) : null);

  function signOut() {
    auth.signOut();
  }

  return { user: profile, signOut, loading: profileLoading };
}

export const [FirebaseProvider, useFirebaseContext] = constate(useFirebaseState);
