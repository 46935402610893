import React from 'react'
import { GiSoccerBall } from 'react-icons/gi'
import { BsPerson } from 'react-icons/bs'
import { BiLogIn } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { useFirebaseContext } from './state/firebase'
import homeIcon from './assets/icons/home.png'

export default function BottomNav() {
  const { user } = useFirebaseContext()
  return (
    <div className='bottom-nav'>
      {/* home icon below */}
      {/* <Link to='/' className='link'>
        <div className='home'>
          <div className='home-icon'>
            <img src={homeIcon} alt='home-icon' />
          </div>
          <div className='home-title'>Hem</div>
        </div>
      </Link> */}
      <Link to='/activities' className='link'>
        <div className='events'>
          <div className='events-icon'>
            <GiSoccerBall />
          </div>
          <div className='events-title'>Aktiviteter</div>
        </div>
      </Link>
      {user ? (
        <Link to='/profile' className='link'>
          <div className='profile'>
            <div className='profile-icon'>
              <BsPerson />
            </div>
            <div className='profile-title'>Profil</div>
          </div>
        </Link>
      ) : (
        <Link to='/login' className='link'>
          <div className='profile'>
            <div className='profile-icon'>
              <BiLogIn />
            </div>
            <div className='profile-title'>Logga in</div>
          </div>
        </Link>
      )}
    </div>
  )
}
