import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDDhIbNy_b-6TuHEpHtyps0vsEjdHtRD04',
  authDomain: 'nattfotboll-app.firebaseapp.com',
  projectId: 'nattfotboll-app',
  storageBucket: 'nattfotboll-app.appspot.com',
  messagingSenderId: '1008903002490',
  appId: '1:1008903002490:web:47eae3df1627e5b7569ec3',
  measurementId: 'G-JW2KV1L4F1',
};
firebase.initializeApp(firebaseConfig);

export default firebaseConfig;
