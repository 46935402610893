import React from 'react';
import LoadingIcon from '../assets/icons/loading-ball.svg';

export function Loading() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
      }}
    >
      <img className='loading-icon' src={LoadingIcon} alt='' />
    </div>
  );
}
