import constate from 'constate';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firestore } from '../firebase';
import { User } from '../types/FirestoreTypings';

function useLeadersState() {
  const [leaders] = useCollectionData<User>(firestore.collection('users').where('rank', '==', 'ledare'));

  function getLeadersById(leaderIds: string[]) {
    return (leaders || []).filter((leader) => leaderIds.includes(leader.uid));
  }

  return { getLeadersById };
}

export const [LeadersProvider, useLeadersContext] = constate(useLeadersState);
