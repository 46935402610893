import { Loading } from './components/Loading'
import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import BottomNav from './bottom-nav'
import { useFirebaseContext } from './state/firebase'

const Home = lazy(() => import('./pages/home'))
const Activities = lazy(() => import('./pages/activities'))
const Activity = lazy(() => import('./pages/activity'))
const Login = lazy(() => import('./pages/login'))
const Signup = lazy(() => import('./pages/signup'))
const CreateTimereg = lazy(() => import('./pages/createTimereg'))
const UserProfile = lazy(() => import('./pages/userProfile'))
const Profile = lazy(() => import('./pages/profile'))
const Stats = lazy(() => import('./pages/stats'))
const LectureParticipantsSignup = lazy(() => import('./pages/lectureParticipantsSignup'))
const LectureLeadersSignup = lazy(() => import('./pages/lectureLeadersSignup'))
const Lecture = lazy(() => import('./pages/lecture'))
const AlblebishEtrophy = lazy(() => import('./pages/alblebishEtrophy'))
const AlblebishRules = lazy(() => import('./pages/alblebishRules'))
const AlblebishSignupPs4Ps5 = lazy(() => import('./pages/alblebishSignupPs4Ps5'))
const AlblebishSignupPs5 = lazy(() => import('./pages/alblebishSignupPs5'))

const Teams = lazy(() => import('./components/teams'))

export default function Routes() {
  const { loading } = useFirebaseContext()
  if (loading) {
    return null
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* change below to Home if needed. uncomment icon in bottom-nav.tsx */}
          <Route exact path='/' component={Activities} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/forelasning' component={Lecture} />
          <Route exact path='/forelasning/anmal' component={LectureParticipantsSignup} />
          <Route exact path='/forelasning/anmal/ledare' component={LectureLeadersSignup} />
          <Route exact path='/alblebish-e-trophy' component={AlblebishEtrophy} />
          <Route exact path='/alblebish-e-trophy/regler' component={AlblebishRules} />
          <Route exact path='/alblebish-e-trophy/ps4ps5' component={AlblebishSignupPs4Ps5} />
          <Route exact path='/alblebish-e-trophy/ps5' component={AlblebishSignupPs5} />
          <Route path='/activities' component={Activities} />
          <Route path='/profile/:id' component={UserProfile} />
          <Route path='/time-report/generate' component={CreateTimereg} />
          <Route path='/activity/:id/teams' component={Teams} />
          <Route path='/activity/:id' component={Activity} />
          <Route path='/stats' component={Stats} />
          <Route path='/login' component={Login} />
          <Route path='/signup' component={Signup} />
        </Switch>
      </Suspense>
      <BottomNav />
    </>
  )
}
