import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import './firebaseConfig';
import { FirebaseProvider } from './state/firebase';
import Routes from './routes';
import { LeadersProvider } from './state/leaders';
import ReactGA from 'react-ga';

import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';

ReactGA.initialize('UA-188995576-1');

function App() {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  const nullEntry: any[] = [];
  const [notifications, setnotifications] = useState(nullEntry);

  if (isPushNotificationsAvailable) {
    useEffect(() => {
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions();
        } else {
          register();
        }
      });
    }, []);
  }

  const register = () => {
    console.log('Initializing HomePage');

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      setnotifications((notifications) => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }]);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      setnotifications((notifications) => [
        ...notifications,
        { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' },
      ]);
    });
  };

  return (
    <LeadersProvider>
      <FirebaseProvider>
        <Router>
          <div className='App'>
            <Routes />
          </div>
        </Router>
      </FirebaseProvider>
    </LeadersProvider>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
